import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { UserSettings, UserSettingsStore } from '../models/user-settings.model';
import { ProgressSpinnerService } from './progress-spinner.service';
import { LocalService } from './local.service';
import { FeedbackDialog } from '../models/feedback-dialog.model';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackService } from './feedback.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  constructor(
    private http: HttpClient,
    private progressSpinnerService: ProgressSpinnerService,
    private localService: LocalService,
    private translate: TranslateService,
    private feedbackService: FeedbackService,
    private router: Router
  ) {}

  //
  // Store of customer
  //
  private readonly _userSettingsStore = new BehaviorSubject<UserSettingsStore>({
    content: {}
  } as UserSettingsStore);

  //
  // Setter userSettings collection
  //

  private _setUserSettingsStore(userSettingsStore: UserSettingsStore): void {
    this._userSettingsStore.next(userSettingsStore);
  }

  //
  // Exposed all userSettings collection as Observable
  //
  readonly userSettingsStore$ = this._userSettingsStore.asObservable();

  //
  // Exposed all userSettings collection as UNObservable
  //
  getUserSettingsStore(): UserSettingsStore {
    return this._userSettingsStore.getValue();
  }

  //
  // Safe update of userSettings collection
  //
  updateUserSettingsStore(userSettingsStore: UserSettingsStore): void {
    this._setUserSettingsStore({
      ...this.getUserSettingsStore(),
      ...userSettingsStore
    });
  }

  //
  // Clean userSettings Id Store
  //
  cleanUserSettingsStore(): void {
    this._setUserSettingsStore({
      content: {}
    } as UserSettingsStore);
  }

  //
  // Fetch an userSettings and store locally in UserSettingsStore
  //
  getUserSettingsHttp(): Observable<void> {
    const sanitizedUsername = this.localService.getSanitizedUsername();
    if (!sanitizedUsername) {
      return of(void 0);
    }

    const baseUri = `/user-settings/get?id=${sanitizedUsername}`;
    return this.http.get<UserSettings>(baseUri).pipe(
      tap(response => {
        const userSettings = response as UserSettings;
        this.updateUserSettingsStore({ content: userSettings });
      }),
      map(() => void 0), // Map the response to void
      catchError(error => {
        console.error('Error fetching user settings', error);
        return of(void 0);
      })
    );
  }

  // PUT Modify or Create UserSettings

  //
  // Http update or create userSettings
  //

  updateUserSettingsHttp(userSettingsUpdate: UserSettings): void {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return;
    }
    if (!this.localService.getSanitizedUsername()) {
      return;
    }
    this.progressSpinnerService.show();
    const sanitizedUsername = this.localService.getSanitizedUsername();

    const uriUserSettings = `/user-settings/update?id=${sanitizedUsername}`;

    this.http.put<UserSettings>(uriUserSettings, userSettingsUpdate).subscribe({
      next: response => {
        const userSettings = response as UserSettings;

        this.updateUserSettingsStore({ content: userSettings });

        const feedbackDialog = {
          title: this.translate.instant('Dialog.Info'),
          message: `${this.translate.instant(
            'Dialog.UserSettingsUpdated'
          )}: <br>
              <b> ${userSettings.email} </b> <br>`,
          style: 'warning'
        } as FeedbackDialog;

        this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
      },
      error: error => {
        const feedbackDialog = {
          id: 'E202',
          title: this.translate.instant('Dialog.Error'),
          message: `${this.translate.instant(
            'Dialog.UserSettingsNotUpdated'
          )}: <br>`,
          style: 'warning'
        } as FeedbackDialog;

        this.feedbackService.setFeedbackStore(feedbackDialog, () => {
          this.router.navigate(['/dashboards/dashboard1']);
        });
      },
      complete: () => {
        this.progressSpinnerService.hide();
        this.router.navigate(['/dashboards/dashboard1']);
      }
    });
  }
}
