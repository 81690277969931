<div mat-dialog-content #dialogContainer>
  <div class="dialog-banner" [ngClass]="getBannerClass()">
    <mat-icon class="dialog-icon">{{ getIcon() }}</mat-icon>
  </div>
  <div class="dialog-content">
    <img
      src="assets/images/logos/salon-care-logo.png"
      alt="Logo"
      class="salon-care-logo"
    />
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div
      *ngIf="data.id; else noIdContent"
      mat-dialog-content
      [innerHTML]="data.message"
    ></div>
  </div>
</div>
<div mat-dialog-actions align="center" class="m-b-10">
  <button mat-button [ngClass]="getButtonClass()" (click)="dialogRef.close()">
    {{ 'General.Ok' | translate }}
  </button>
</div>
<ng-template #noIdContent>
  <div mat-dialog-content mat-dialog-content [innerHTML]="data.message"></div>
</ng-template>
