<mat-toolbar class="topbar style-fonts">
  <!-- --------------------------------------------------------------- -->
  <!-- Desktop Menu -->
  <button
    id="menu-vertical-toggle"
    mat-icon-button
    (click)="toggleNav()"
    *ngIf="showToggle"
    class="d-flex justify-content-center style-fonts-bold"
  >
    <i-tabler
      name="menu-2"
      [style]="isNavOpen ? 'color: #B3C6D5' : 'color:  #0A7EA4'"
      [class]="isNavOpen ? 'icon-20 d-flex' : 'icon-20 d-flex'"
    ></i-tabler>
  </button>
  <!-- Mobile Menu -->
  <button
    mat-icon-button
    (click)="toggleMobileNav.emit()"
    *ngIf="!showToggle"
    class="d-flex justify-content-center style-fonts-bold"
  >
    <i-tabler
      name="menu-2"
      [class]="
        isNavOpen ? 'icon-20 d-flex red' : 'icon-20 d-flex style-fonts-bold'
      "
    ></i-tabler>
  </button>
  <!-- --------------------------------------------------------------- -->

  <!-- --------------------------------------------------------------- -->
  <!--  Search -->
  <!-- --------------------------------------------------------------- -->
  <button
    id="menu-search"
    mat-icon-button
    (click)="openDialog()"
    class="d-flex justify-content-center"
  >
    <i-tabler
      name="search"
      class="icon-20 d-flex"
      [ngStyle]="{ color: '#0A7EA4' }"
    ></i-tabler>
  </button>

  <div class="d-none d-lg-flex">
    <!-- --------------------------------------------------------------- -->
    <!--  Links -->
    <!-- --------------------------------------------------------------- -->
    <mat-menu #appsmenu="matMenu" class="apps-dd cardWithShadow">
      <!-- <div class="row">
        <div class="col-sm-8 b-r-1 p-r-0">
          <div class="p-32 p-b-0">
            <div class="row">
              <div
                class="col-sm-6 text-hover-primary"
                *ngFor="let appdd of apps"
              >
                <a
                  [routerLink]="[appdd.link]"
                  class="style-fonts d-flex align-items-center m-b-24"
                >
                  <span
                    class="text-primary bg-light-primary rounded icon-40 d-flex align-items-center justify-content-center"
                  >
                    <img [src]="appdd.img" width="20" />
                  </span>

                  <div class="m-l-16">
                    <h5
                      class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text style-fonts-bold"
                    >
                      {{ appdd.title }}
                    </h5>
                    <span class="mat-body-1 f-s-12">{{ appdd.subtitle }}</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div
            class="b-t-1 p-24 d-none d-lg-flex align-items-center justify-content-between"
          >
            <span
              class="d-flex align-items-center mat-subtitle-1 f-s-16 f-w-500"
            >
              <i-tabler name="help" class="icon-20 m-r-8"></i-tabler>Frequently
              Asked Questions
            </span>
            <a
              [routerLink]="['/theme-pages/faq']"
              mat-flat-button
              color="primary"
              >Check</a
            >
          </div>
        </div>
        <div class="col-sm-4">
          <div class="p-x-16 p-y-32">
            <h4 class="f-s-18 f-w-600 mat-subtitle-1 m-b-16">
              Quick Links dupout2
            </h4>
            <div
              class="text-hover-primary"
              *ngFor="let quicklink of quicklinks"
            >
              <a
                [routerLink]="[quicklink.link]"
                class="hover-text style-fonts mat-body-1 f-w-600 d-block p-y-8"
                >{{ quicklink.title }}</a
              >
            </div>
          </div>
        </div>
      </div> -->
    </mat-menu>

    <ng-container *ngFor="let item of shortCutNavItems">
      <a class="style-fonts" mat-button [routerLink]="[item.route]">
        {{ 'General.' + item.displayName! | translate }}
      </a>
    </ng-container>

    <!-- <a mat-button [routerLink]="['/apps/calendar']"
      >{{ "Calendar" | translate }}</a
    >

    <a mat-button [routerLink]="['/apps/calendar']"
      >{{ "Calendar" | translate }}</a
    >
    <a mat-button [routerLink]="['/apps/invoice']">{{
      "Visits" | translate
    }}</a>
    <a mat-button [routerLink]="['/apps/invoice']">{{
      "Customers" | translate
    }}</a>
    <a mat-button [routerLink]="['/apps/notes']">{{ "Notes" | translate }}</a>
    <a mat-button [routerLink]="['/apps/todo']">{{ "ToDo" | translate }}</a> -->
  </div>

  <span class="flex-1-auto"></span>

  <!-- Mobile Menu -->
  <button
    id="menu-mobile-grid"
    mat-icon-button
    (click)="toggleMobileFilterNav.emit()"
    class="d-flex d-lg-none justify-content-center"
  >
    <i-tabler
      name="grid-dots"
      class="icon-20 d-flex"
      [ngStyle]="{ color: '#0A7EA4' }"
    ></i-tabler>
  </button>

  <!-- --------------------------------------------------------------- -->
  <!-- Login/Logout -->
  <!-- --------------------------------------------------------------- -->

  <div *ngIf="user$ | async as user; else loginTemplate" class="m-l-auto">
    <!-- ddd3_true -->

    <a
      mat-icon-button
      (click)="logout()"
      class="d-flex justify-content-center"
      matTooltip="Logout"
    >
      <i-tabler
        name="power"
        class="text-primary icon-18 d-flex icon-on"
      ></i-tabler>
    </a>
  </div>
  <ng-template #loginTemplate>
    <div class="m-l-auto">
      <!-- ddd3_null -->

      <a
        mat-icon-button
        (click)="login()"
        class="d-flex justify-content-center"
        matTooltip="Login"
      >
        <i-tabler
          name="power"
          class="text-primary icon-18 d-flex icon-off"
        ></i-tabler>
      </a>
    </div>
  </ng-template>

  <!-- --------------------------------------------------------------- -->
  <!-- langugage Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
    <img
      [src]="selectedLanguage.icon"
      class="rounded-circle object-cover icon-20"
    />
  </button>
  <mat-menu #flags="matMenu" class="cardWithShadow">
    <button
      mat-menu-item
      *ngFor="let lang of languages"
      (click)="changeLanguage(lang)"
    >
      <div class="d-flex align-items-center">
        <img
          [src]="lang.icon"
          class="rounded-circle object-cover m-r-8 icon-20"
        />
        <span class="mat-subtitle-1 f-s-14">{{ lang.language }}</span>
      </div>
    </button>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- Notification Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!-- TODO: will be implemented in the future -->

  <!-- <button
    mat-icon-button
    [matMenuTriggerFor]="notificationmenu"
    aria-label="Notifications"
  >

    <i-tabler
      class="d-flex"
      name="bell"
      matBadge="1"
      [ngStyle]="{ color: '#0A7EA4' }"
    ></i-tabler>
  </button>
  <mat-menu #notificationmenu="matMenu" class="topbar-dd cardWithShadow">
    <div class="d-flex align-items-center p-x-32 p-y-16">
      <h6 class="f-s-16 f-w-600 m-0">Notifications</h6>
      <span class="m-l-auto">
        <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12"
          >5 new</span
        >
      </span>
    </div>
    <button
      mat-menu-item
      class="p-x-32 p-y-16"
      *ngFor="let notification of notifications"
    >
      <div class="d-flex align-items-center">
        <img [src]="notification.img" class="rounded-circle" width="48" />
        <div class="m-l-16">
          <h5
            class="style-fonts f-s-14 f-w-600 m-0 mat-subtitle-1 style-fonts-bold"
          >
            {{ notification.title }}
          </h5>
          <span class="style-fonts">{{ notification.subtitle }}</span>
        </div>
      </div>
    </button>
    <div class="p-y-12 p-x-32">
      <button mat-stroked-button color="primary" class="w-100">
        See all notifications
      </button>
    </div>
  </mat-menu> -->

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <button
    *ngIf="user$ | async as user"
    mat-icon-button
    [matMenuTriggerFor]="profilemenu"
    aria-label="User Profile"
  >
    <img
      *ngIf="user.photoURL"
      [src]="user.photoURL"
      alt="User photo"
      class="rounded-circle object-cover"
      width="35"
      referrerpolicy="no-referrer"
    />
  </button>
  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
    <ng-scrollbar class="position-relative" style="height: 647px">
      <div class="p-x-32 p-y-16" *ngIf="user$ | async as user">
        <h6 class="f-s-16 f-w-600 m-0">
          {{ 'General.UserProfile' | translate }}
        </h6>

        <div
          *ngIf="user$ | async as user"
          class="d-flex align-items-center p-b-24 b-b-1 m-t-16"
        >
          <img
            *ngIf="user.photoURL"
            [src]="user.photoURL"
            alt="User photo"
            class="rounded-circle"
            width="60"
          />
          <div class="m-l-16">
            <h6 class="f-s-14 f-w-600 m-0">
              {{ user.displayName }}
            </h6>
            <span class="f-s-14 d-block m-b-4">
              {{ userSettingsService.getUserSettingsStore().content.title }}
            </span>
            <span class="d-flex align-items-center">
              <i-tabler
                name="mail"
                class="icon-15 m-r-4"
                [ngStyle]="{ color: '#0A7EA4' }"
              ></i-tabler>
              {{ user.email }}
            </span>
          </div>
        </div>
      </div>

      <div class="p-x-32">
        <a
          class="p-y-16 text-decoration-none d-block text-hover-primary"
          [routerLink]="[profile.link]"
          *ngFor="let profile of profiledd"
        >
          <div class="d-flex align-items-center">
            <button
              mat-mini-fab
              class="text-primary bg-light-primary shadow-none rounded"
            >
              <img [src]="profile.img" width="20" />
            </button>

            <div class="m-l-16">
              <h5
                class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text style-fonts-bold"
              >
                {{ profile.title }}
              </h5>
              <span class="mat-body-1">{{ profile.subtitle }}</span>
            </div>
          </div>
        </a>

        <!-- upgrade -->
        <!-- bg-light-primary -->
        <div class="p-24 overflow-hidden rounded position-relative m-y-16">
          <div class="d-flex align-items-center">
            <div>
              <h5 class="f-s-18 m-0 f-w-600 m-b-12">
                {{ 'General.Unlimited' | translate }}<br />
                {{ 'General.Access' | translate }}
              </h5>
              <button
                mat-button
                mat-flat-button
                color="primary"
                [routerLink]="['/apps/pricing']"
              >
                {{ 'General.Upgrade' | translate }}
              </button>
            </div>
            <div class="m-l-auto">
              <img
                src="/assets/images/backgrounds/unlimited-bg.png"
                alt="upgrade-bg"
                class="upgrade-bg"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="p-y-12 p-x-32">
        <a
          mat-button
          [routerLink]="['/apps/logout']"
          mat-flat-button
          class="w-100"
          color="primary"
          >{{ 'General.Logout' | translate }}</a
        >
      </div>
    </ng-scrollbar>
  </mat-menu>
</mat-toolbar>
