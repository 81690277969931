import { Injectable } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

export interface Code {
  label: string;
  value: string;
  flag: string;
}

@Injectable({
  providedIn: 'root'
})
export class UtilData {
  HOURS = [
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22'
  ];
  MINUTES = ['00', '15', '30', '45'];

  EVENT_COLORS = [
    // {id: 0, value: '#FF000040', textColor: 'Red'},
    // {id: 1, value: '#0000FF40', textColor: 'Blue'},
    { id: 0, value: '#FFFF0040', textColor: 'Yellow' },
    { id: 1, value: '#00FF0040', textColor: 'Green' },
    { id: 2, value: '#99999940', textColor: 'Grey' },
    { id: 3, value: '#00FFFF40', textColor: 'Aqua' },
    { id: 4, value: '#FF00FF40', textColor: 'Fuchsia' },
    { id: 5, value: '#EC706340', textColor: 'Orange' }
  ];

  EDITOR_EDITABLE_CONFIG: AngularEditorConfig = {
    editable: true,
    // spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' }
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage', 'insertVideo', 'toggleEditorMode']]
  };

  EDITOR_READONLY_CONFIG: AngularEditorConfig = {
    enableToolbar: false,
    showToolbar: false,
    editable: false,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto'
  };

  COUNTRY_CODES = [
    {
      label: 'PL (+48)',
      value: '0048',
      flag: './../../../assets/images/flag/pl.svg'
    },
    {
      label: 'CZ (+420)',
      value: '00420',
      flag: './../../../assets/images/flag/cz.svg'
    },
    {
      label: 'DE (+49)',
      value: '0049',
      flag: './../../../assets/images/flag/de.svg'
    },
    {
      label: 'FR (+33)',
      value: '0033',
      flag: './../../../assets/images/flag/fr.svg'
    },
    {
      label: 'UA (+380)',
      value: '00380',
      flag: './../../../assets/images/flag/ua.svg'
    },
    {
      label: 'UK (+44)',
      value: '0044',
      flag: './../../../assets/images/flag/gb.svg'
    },
    {
      label: 'NL (+31)',
      value: '+31',
      flag: './../../../assets/images/flag/nl.svg'
    }
  ] as Code[];

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  calculateGrossPrice(netPrice: number): number {
    const taxRate = 0.23; // 23% tax rate
    const grossPrice = netPrice * (1 + taxRate);
    return grossPrice;
  }
}
