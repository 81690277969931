<!-- color="primary" -->
<div class="p-24 p-b-0">
  <div class="row">
    <div class="col-10">
      <!-- matInput -->

      <mat-form-field
        appearance="outline"
        class="hide-hint w-100 custom-form-field"
      >
        <input
          matInput
          placeholder="{{ 'General.SearchHere' | translate }}"
          [(ngModel)]="searchText"
          [ngStyle]="{ border: '1px solid #0A7EA4' }"
          class="custom-input"
        />
      </mat-form-field>
    </div>
    <div class="col-2 d-flex justify-content-end">
      <button
        mat-icon-button
        mat-dialog-close
        class="d-flex justify-content-center"
      >
        <i-tabler
          name="x"
          class="icon-18 d-flex"
          [ngStyle]="{ color: '#0A7EA4' }"
        ></i-tabler>
      </button>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<!-- <mat-dialog-content class="mat-typography search-dialog"> -->

<mat-dialog-content class="dialog-height">
  <h4 class="f-s-18 f-w-600 mat-body-1 m-b-16">
    {{ 'General.QuickPageLinks' | translate }}
  </h4>

  <a
    *ngFor="let item of filteredNavItems"
    [routerLink]="[item.route]"
    mat-dialog-close
    class="p-y-12 text-decoration-none d-block shortcut-line"
  >
    <!-- [class.primary-color]="true" -->

    <div class="shortcut-line">
      <mat-icon matSuffix>
        <i-tabler
          [name]="item.iconName!"
          class="icon-20"
          [ngStyle]="{ color: '#0A7EA4' }"
        ></i-tabler>
      </mat-icon>
      <h5 class="f-s-14 f-w-500 d-block mat-subtitle-2 m-0">
        {{ 'General.' + item.displayName | translate }}
      </h5>
    </div>
    <span class="f-s-12 mat-body-2">{{ item.route }}</span>
  </a>
</mat-dialog-content>
