import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdService {
  private fullScreenAdSubject = new BehaviorSubject<boolean>(false);
  fullScreenAd$ = this.fullScreenAdSubject.asObservable();

  constructor() {}

  showFullScreenAd(): void {
    // console.log('## showFullScreenAd');
    this.fullScreenAdSubject.next(true);
  }

  hideFullScreenAd(): void {
    // console.log('## hideFullScreenAd');
    this.fullScreenAdSubject.next(false);
  }
}
