import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  map,
  of,
  switchMap,
  tap
} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Customer, CustomerStore } from '../models/customer.model';

import { Address } from '../models/address.model';
import { FeedbackService } from './feedback.service';
import { ProgressSpinnerService } from './progress-spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackDialog } from '../models/feedback-dialog.model';
import { Router } from '@angular/router';
import { LocalService } from './local.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  constructor(
    private http: HttpClient,
    private feedbackService: FeedbackService,
    private translate: TranslateService,
    private router: Router,
    private localService: LocalService,
    private progressSpinnerService: ProgressSpinnerService
  ) {}
  //
  // Store of customer id
  //
  private _customerIdStore = new BehaviorSubject<number>(0);
  public readonly customerId$ = this._customerIdStore.asObservable(); // it is read only

  setCustomerIdStore(id: number): void {
    this._customerIdStore.next(id);
  }

  //
  // Clean customer Id Store
  //
  cleanCustomerIdStore(): void {
    this._customerIdStore.next(0);
  }

  //
  // Expose Customer's Id as UNObservable, as number
  //
  getCustomerIdStore(): number {
    return this._customerIdStore.getValue();
  }

  //
  // Store of customer
  //
  private readonly _customerStore = new BehaviorSubject<CustomerStore>({
    content: []
  } as CustomerStore);

  //
  // Setter customer collection
  //
  private _setCustomerStore(customerStore: CustomerStore): void {
    this._customerStore.next(customerStore);
  }

  //
  // Exposed all customer collection as Observable
  //
  readonly customerStore$ = this._customerStore.asObservable();

  //
  // Exposed all customer collection as UNObservable
  //
  getCustomerStore(): CustomerStore {
    return this._customerStore.getValue();
  }

  //
  // Safe update of customer collection
  //
  updateCustomerStore(customerStore: CustomerStore): void {
    this._setCustomerStore({
      ...this.getCustomerStore(),
      ...customerStore
    });
  }

  //
  // Clean project's customer
  //
  cleanCustomerStore(): void {
    this._setCustomerStore({
      content: []
    } as CustomerStore);
  }

  //
  // Http create customer and its address
  //
  createCustomerHttp(
    customer: Customer,
    address: Address
  ): Observable<Customer> {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return of(null as any);
    }
    if (!this.localService.getSanitizedUsername()) {
      return of(null as any);
    }
    const sanitizedUsername = this.localService.getSanitizedUsername();
    this.progressSpinnerService.show();

    const uriCustomer = `/customers/create?id=${sanitizedUsername}`;

    type ResponseNewCustomerType = {
      customer?: Customer;
      message: string;
    };

    return this.http.post<ResponseNewCustomerType>(uriCustomer, customer).pipe(
      switchMap(response => {
        const message = response.message;
        const createdCustomer = response.customer as Customer;

        const allCustomers = this.getCustomerStore().content as Customer[];
        allCustomers.push(createdCustomer);

        this.updateCustomerStore({ content: allCustomers });
        return this.createAddressHttp(createdCustomer, address).pipe(
          map(() => createdCustomer)
        );
      }),
      tap({
        next: () => this.progressSpinnerService.hide(),
        error: () => this.progressSpinnerService.hide()
      }),
      catchError(error => {
        const feedbackDialog = {
          id: 'E100',
          title: this.translate.instant('Dialog.Error'),
          message: `${this.translate.instant('Dialog.CustomerNotCreated')}: <br>
          ${customer.name} ${customer.surname} <br>`,
          style: 'warning'
        } as FeedbackDialog;

        const subject = new Subject<void>();

        this.feedbackService.setFeedbackStore(feedbackDialog, () => {
          this.router.navigate(['/apps/customer']);
          subject.next(); // Signal completion
          subject.complete();
        });

        return subject.pipe(map(() => null as any));
      })
    );
  }

  //
  // Http create customer and its address
  //
  createAddressHttp(customer: Customer, address: Address): Observable<void> {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return of(void 0);
    }
    if (!this.localService.getSanitizedUsername()) {
      return of(void 0);
    }
    const sanitizedUsername = this.localService.getSanitizedUsername();
    const addressWithId = { ...address, ...{ id: customer.addressId } };
    const uriAddress = `/addresses/create?id=${sanitizedUsername}`;

    return this.http.post<void>(uriAddress, addressWithId).pipe(
      tap({
        next: () => {
          const feedbackDialog = {
            title: this.translate.instant('Dialog.Info'),
            message: `${this.translate.instant('Dialog.CustomerCreated')}: <br>
            ${customer.name} ${customer.surname} <br>`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {
            //this.router.navigate(['/dashboards/dashboard1']);
          });
        },
        error: error => {
          const feedbackDialog = {
            id: 'E101',
            title: this.translate.instant('Dialog.Error'),
            message: `${this.translate.instant('Dialog.AddressError')}: <br>
            ${customer.name} ${customer.surname} <br>`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
        },
        complete: () => {
          this.progressSpinnerService.hide();
          if (this.router.url === '/apps/addCustomer') {
            this.router.navigate(['/apps/customer']);
          }
        }
      }),
      catchError(error => {
        const feedbackDialog = {
          id: 'E101',
          title: this.translate.instant('Dialog.Error'),
          message: `${this.translate.instant('Dialog.AddressError')}: <br>
          ${customer.name} ${customer.surname} <br>`,
          style: 'warning'
        } as FeedbackDialog;

        const subject = new Subject<void>();

        this.feedbackService.setFeedbackStore(feedbackDialog, () => {
          subject.next(); // Signal completion
          subject.complete();
        });

        return subject.pipe(map(() => void 0));
      })
    );
  }
  // PUT Modify

  //
  // Http update customer and its address
  //

  updateCustomerHttp(customerUpdate: Customer, addressUpdate: Address): void {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return;
    }
    if (!this.localService.getSanitizedUsername()) {
      return;
    }
    const sanitizedUsername = this.localService.getSanitizedUsername();
    this.progressSpinnerService.show();

    const uriCustomer = `/customers/update?id=${sanitizedUsername}`;

    type ResponseNewCustomerType = {
      customer?: Customer;
      message: string;
    };

    this.http
      .put<ResponseNewCustomerType>(uriCustomer, customerUpdate)
      .subscribe({
        next: response => {
          const allCustomers = this.getCustomerStore().content as Customer[];

          const updatedCustomers = allCustomers.map((customer: Customer) =>
            customer.id === customerUpdate.id
              ? {
                  ...customer,
                  name: customerUpdate.name,
                  surname: customerUpdate.surname,
                  born: customerUpdate.born,
                  phone: customerUpdate.phone,
                  email: customerUpdate.email,
                  description: customerUpdate.description
                }
              : customer
          );
          this.updateCustomerStore({ content: updatedCustomers });
          this.updateAddressHttp(customerUpdate, addressUpdate);
        },
        error: error => {
          const feedbackDialog = {
            id: 'E102',
            title: this.translate.instant('Dialog.Error'),
            message: `${this.translate.instant('Dialog.CustomerError')}: <br>
            ${customerUpdate.name} ${customerUpdate.surname} <br>`,
            style: 'warning'
          } as FeedbackDialog;

          this.feedbackService.setFeedbackStore(feedbackDialog, () => {
            this.router.navigate(['/apps/customer']);
          });
        },
        complete: () => {
          this.progressSpinnerService.hide();
          this.router.navigate(['/apps/customer']);
        }
      });
  }

  //
  // Http update customer and its address
  //

  updateAddressHttp(customerUpdate: Customer, addressUpdate: Address): void {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return;
    }
    if (!this.localService.getSanitizedUsername()) {
      return;
    }
    const sanitizedUsername = this.localService.getSanitizedUsername();
    const addressWithId = {
      ...addressUpdate,
      ...{ id: customerUpdate.addressId }
    };
    const uriAddress = `/addresses/update?id=${sanitizedUsername}`;

    this.http.put(uriAddress, addressWithId).subscribe({
      next: response => {
        const feedbackDialog = {
          title: this.translate.instant('Dialog.Info'),
          message: `${this.translate.instant('Dialog.CustomerUpdated')}: <br>
          ${customerUpdate.name} ${customerUpdate.surname} <br>`,
          style: 'warning'
        } as FeedbackDialog;
        this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
      },
      error: error => {
        const feedbackDialog = {
          id: 'E103',
          title: this.translate.instant('Dialog.Info'),
          message: `${this.translate.instant('Dialog.CustomerNotUpdated')}: <br>
          ${customerUpdate.name} ${customerUpdate.surname} <br>`,
          style: 'warning'
        } as FeedbackDialog;
        this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
      },
      complete: () => {
        this.progressSpinnerService.hide();
        this.router.navigate(['/apps/customer']);
      }
    });
  }

  getCustomer$(id: number): Observable<Customer> {
    return this.customerStore$.pipe(
      map(customerStore => customerStore.content),
      map(customers => customers.filter(customer => customer.id === id)[0])
    );
  }

  getCustomerBasedOnId$(): Observable<Customer> {
    return this.customerId$.pipe(
      switchMap(customerId =>
        this.customerStore$.pipe(
          map(customerStore => customerStore.content),
          map(
            customers =>
              customers.filter(customer => customer.id === customerId)[0]
          )
        )
      )
    );
  }

  getAddressIdBasedOnCustomerId(): number {
    return (
      this.getCustomerStore().content.filter(
        customer => customer.id === this.getCustomerIdStore()
      )[0].addressId ?? 0
    );
  }

  getCustomerBasedOnCustomerId(): Customer {
    return (
      this.getCustomerStore().content.filter(
        customer => customer.id === this.getCustomerIdStore()
      )[0] ?? null
    );
  }

  getCustomerBasedOnId(id: number): Customer {
    return (
      this.getCustomerStore().content.filter(
        customer => customer.id === id
      )[0] ?? null
    );
  }

  findCustomerByFullName(fullName: string): Customer | undefined {
    const customerStore = this.getCustomerStore();
    return customerStore.content.find(customer => {
      const customerFullName = customer.name + ' ' + (customer.surname || '');
      return customerFullName === fullName;
    });
  }

  //
  // Http request for customers
  //

  getAllCustomersHttp(): Observable<void> {
    const sanitizedUsername = this.localService.getSanitizedUsername();
    // if (!sanitizedUsername) {
    //   return of(void 0);
    // }
    const uri = `/customers/all?id=${sanitizedUsername}`;
    this.cleanCustomerStore();

    return this.http.get<Customer[]>(uri).pipe(
      tap(response => {
        const allCustomers = response as Customer[];
        this.updateCustomerStore({ content: allCustomers });
      }),
      map(() => void 0),
      catchError(error => {
        console.error('Error fetching customers', error);

        const feedbackDialog = {
          id: 'E105',
          title: this.translate.instant('Dialog.Error'),
          message: `${this.translate.instant('Dialog.AllCustomerNotFetched')}`,
          style: 'warning'
        } as FeedbackDialog;
        const subject = new Subject<void>();

        this.feedbackService.setFeedbackStore(feedbackDialog, () => {
          this.router.navigate(['/apps/customer']);
          subject.next(); // Signal completion
          subject.complete();
        });
        return subject.pipe(map(() => void 0));
      }),
      tap(() => {})
    );
  }

  //
  // Http delete customer and its address
  //

  deleteCustomerHttp(customerId: number): void {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return;
    }
    if (!this.localService.getSanitizedUsername()) {
      return;
    }
    const sanitizedUsername = this.localService.getSanitizedUsername();
    this.progressSpinnerService.show();

    const baseUri = `/customers/delete?id=${sanitizedUsername}`;
    const options = {
      body: { id: customerId } // Include customerId in the request body
    };

    this.http
      .delete(`${baseUri}`, options)
      .pipe()
      .subscribe({
        next: response => {
          const updatedCustomers = this.getCustomerStore().content.filter(
            customer => customer.id !== customerId
          );
          const deletedCustomer = this.getCustomerStore().content.filter(
            customer => customer.id == customerId
          )[0];
          const feedbackDialog = {
            title: this.translate.instant('Dialog.Info'),
            message: `${this.translate.instant('Dialog.CustomerDeleted')}: <br>
            ${deletedCustomer.name} ${deletedCustomer.surname} <br>`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
          this.updateCustomerStore({ content: updatedCustomers });
          this.deleteAddressHttp(deletedCustomer);

          //TODO: Perform next action: remove its Visits etc
          //TODO: return message for dialog
        },
        error: error => {
          const feedbackDialog = {
            id: 'E106',
            title: this.translate.instant('Dialog.Error'),
            message: `${this.translate.instant('Dialog.CustomerNotDeleted')}`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
        },
        complete: () => {
          this.progressSpinnerService.hide();
          this.router.navigate(['/apps/customer']);
        }
      });
  }

  deleteAddressHttp(deletedCustomer: Customer): void {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return;
    }
    if (!this.localService.getSanitizedUsername()) {
      return;
    }
    const sanitizedUsername = this.localService.getSanitizedUsername();

    const baseUri = `/addresses/delete?id=${sanitizedUsername}`;
    const options = {
      body: { id: deletedCustomer.addressId } // Include customerId in the request body
    };

    this.http
      .delete(`${baseUri}`, options)
      .pipe()
      .subscribe({
        next: response => {
          const feedbackDialog = {
            title: this.translate.instant('Dialog.Info'),
            message: `${this.translate.instant('Dialog.AddressDeleted')}: <br>
            ${deletedCustomer.name} ${deletedCustomer.surname} <br>`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});

          //TODO: Perform next action: remove its Visits etc
        },
        error: error => {
          const feedbackDialog = {
            id: 'E106',
            title: this.translate.instant('Dialog.Error'),
            message: `${this.translate.instant(
              'Dialog.CustomerNotDeleted'
            )}: <br>
            ${deletedCustomer.name} ${deletedCustomer.surname} <br>`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
        },
        complete: () => {
          this.progressSpinnerService.hide();
          this.router.navigate(['/apps/customer']);
        }
      });
  }
}
