<mat-sidenav-container
  class="mainWrapper"
  autosize
  autoFocus
  [ngClass]="{
    'sidebarNav-mini': options.sidenavCollapsed && options.navPos !== 'top',
    'sidebarNav-horizontal': options.horizontal,
    cardBorder: options.cardBorder,
    orange_theme: options.activeTheme == 'orange_theme',
    blue_theme: options.activeTheme == 'blue_theme',
    aqua_theme: options.activeTheme == 'aqua_theme',
    purple_theme: options.activeTheme == 'purple_theme',
    green_theme: options.activeTheme == 'green_theme',
    cyan_theme: options.activeTheme == 'cyan_theme'
  }"
  [dir]="options.dir!"
>
  <!-- ============================================================== -->
  <!-- Vertical Sidebar -->
  <!-- ============================================================== -->

  <mat-sidenav
    #leftsidenav
    [mode]="isOver ? 'over' : 'side'"
    [opened]="options.navPos === 'side' && options.sidenavOpened && !isOver"
    *ngIf="!options.horizontal"
    (openedChange)="onSidenavOpenedChange($event)"
    (closedStart)="onSidenavClosedStart()"
    class="sidebarNav"
    (click)="checkWidth(1)"
  >
    <!-- LOGO -->
    <div class="flex-layout">
      <div
        [ngClass]="{ expanded: isSidenavExpanded, narrow: !isSidenavExpanded }"
      >
        <img
          id="logo1"
          src="./assets/images/logos/salon-care-logo.png"
          alt="Logo"
          class="logo"
        />
      </div>
      <ng-scrollbar class="position-relative" style="height: 100%">
        <mat-nav-list class="sidebar-list">
          <app-nav-item
            *ngFor="let item of navItems"
            [item]="item"
            (notify)="sidenav.toggle()"
          >
          </app-nav-item>
        </mat-nav-list>
      </ng-scrollbar>
      <div class="p-24 m-t-auto profile-bar">
        <div
          class="bg-light-primary-gradient d-flex align-items-center rounded p-16"
        >
          <ng-container *ngIf="user$ | async as user">
            <img
              id="avatar1"
              *ngIf="user.photoURL"
              [src]="user.photoURL"
              alt="User photo"
              class="rounded-circle object-cover"
              width="40"
              referrerpolicy="no-referrer"
            />
          </ng-container>

          <div
            *ngIf="user$ | async as user; else notLoggedTemplate"
            class="m-l-16"
          >
            <h5 class="mat-subtitle-2 f-w-600">
              {{ name$ | async }}
            </h5>
            <span class="f-s-12">{{
              userSettingsService.getUserSettingsStore().content.title
            }}</span>
          </div>
          <ng-template #notLoggedTemplate>
            <div class="m-l-16">
              <h5 class="mat-subtitle-2 f-w-600">
                {{ 'Authentication.NotLoggedIn' | translate }}
              </h5>
              <span class="f-s-12">{{
                'Authentication.DemoVersion' | translate
              }}</span>
            </div>
          </ng-template>
          <div
            *ngIf="user$ | async as user; else loginTemplate"
            class="m-l-auto"
          >
            <!-- ddd1_true -->
            <a
              mat-icon-button
              (click)="logout()"
              class="d-flex justify-content-center"
              matTooltip="Logout"
            >
              <i-tabler
                name="power"
                class="text-primary icon-18 d-flex icon-on"
              ></i-tabler>
            </a>
          </div>
          <ng-template #loginTemplate>
            <div class="m-l-auto">
              <!-- ddd2_false -->

              <a
                mat-icon-button
                (click)="login()"
                class="d-flex justify-content-center"
                matTooltip="Login"
              >
                <i-tabler
                  name="power"
                  class="text-primary icon-18 d-flex icon-off"
                ></i-tabler>
              </a>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <!-- ============================================================== -->
  <!-- horizontal Sidebar -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- Main Content -->
  <!-- ============================================================== -->
  <mat-sidenav-content class="contentWrapper" #content>
    <!-- ============================================================== -->
    <!-- VerticalHeader -->
    <!-- ============================================================== -->
    <ng-container>
      <app-header
        [showToggle]="!isOver"
        (toggleCollapsed)="toggleCollapsed()"
        (toggleMobileNav)="sidenav.toggle()"
        (toggleMobileFilterNav)="filterNavRight.toggle()"
      ></app-header>
    </ng-container>

    <main
      class="pageWrapper"
      [ngClass]="{
        maxWidth: options.boxed
      }"
    >
      <app-breadcrumb class="m-b-8"></app-breadcrumb>

      <!-- Demo: -->
      <!-- <div *ngIf="isDemo" id="container" class="m-b-10"> -->
      <ng-container *ngIf="user$ | async as user; else demoBanner">
        <!-- Content when user is logged in -->
      </ng-container>
      <ng-template id="demoBanner" #demoBanner>
        <div id="container" class="m-y-10">
          <div class="scroll">
            <div *ngFor="let item of repeatArray" class="item">
              {{ 'General.DemoBannerContent' | translate }}
            </div>
          </div>
          <div class="fade"></div>
        </div>
      </ng-template>

      <!-- ============================================================== -->
      <!-- Outlet -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <div class="customizerBtn">
        <button
          id="customizerBtn"
          mat-fab
          color="primary"
          (click)="customizerRight.toggle()"
        >
          <mat-icon>settings</mat-icon>
        </button>
      </div>
    </main>
  </mat-sidenav-content>

  <mat-sidenav
    #customizerRight
    mode="over"
    position="end"
    (click)="checkWidth(3)"
  >
    <app-customizer (optionsChange)="receiveOptions($event)"></app-customizer>
  </mat-sidenav>
</mat-sidenav-container>

<!-- ------------------------------------------------------------------
    Mobile Apps Sidebar
    ------------------------------------------------------------------ -->
<mat-drawer #filterNavRight mode="over" position="end" class="filter-sidebar">
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="branding">
        <a>
          <img
            id="menu-mobile-logo"
            src="./assets/images/logos/salon-care-logo.png"
            class="align-middle m-2"
            alt="logo"
            (click)="
              navigateTo('/dashboards/dashboard1'); filterNavRight.toggle()
            "
          />
        </a>
      </div>
      <button
        id="menu-mobile-x"
        mat-icon-button
        (click)="filterNavRight.toggle()"
        class="d-flex justify-content-center"
      >
        <i-tabler
          name="x"
          class="icon-18 d-flex"
          [ngStyle]="{ color: '#0A7EA4' }"
        ></i-tabler>
      </button>
    </div>

    <div class="p-x-24">
      <!-- Home -->
      <div class="shortcut-line">
        <mat-icon matSuffix>
          <i-tabler
            name="home"
            class="icon-20"
            [ngStyle]="{ color: '#0A7EA4' }"
          ></i-tabler>
        </mat-icon>
        <a
          id="menu-mobile-home"
          (click)="navigateTo(''); filterNavRight.toggle()"
          class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
          ><span class="f-s-15 f-w-500 m-l-8">{{
            'General.Home' | translate
          }}</span></a
        >
      </div>
      <!-- Calendar -->
      <div class="shortcut-line">
        <mat-icon matSuffix>
          <i-tabler
            name="calendar-event"
            class="icon-20"
            [ngStyle]="{ color: '#0A7EA4' }"
          ></i-tabler>
        </mat-icon>
        <a
          id="menu-mobile-calendar"
          (click)="navigateTo('/apps/calendar'); filterNavRight.toggle()"
          class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
          ><span class="f-s-15 f-w-500 m-l-8">{{
            'General.Calendar' | translate
          }}</span></a
        >
      </div>

      <div class="shortcut-line-sub">
        <mat-icon matSuffix>
          <i-tabler
            name="circle-plus"
            class="icon-20"
            [ngStyle]="{ color: '#0A7EA4' }"
          ></i-tabler>
        </mat-icon>
        <a
          id="menu-mobile-add-visit"
          (click)="navigateTo('/apps/addVisit'); filterNavRight.toggle()"
          class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
          ><span class="f-s-15 f-w-500 m-l-8">{{
            'General.AddVisit' | translate
          }}</span></a
        >
      </div>

      <!-- Customer -->

      <div class="shortcut-line">
        <mat-icon matSuffix>
          <i-tabler
            name="man"
            class="icon-20"
            [ngStyle]="{ color: '#0A7EA4' }"
          ></i-tabler>
        </mat-icon>
        <a
          id="menu-mobile-customer"
          (click)="navigateTo('/apps/customer'); filterNavRight.toggle()"
          class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
          ><span class="f-s-15 f-w-500 m-l-8">{{
            'General.Customers' | translate
          }}</span></a
        >
      </div>

      <div class="shortcut-line-sub">
        <mat-icon matSuffix>
          <i-tabler
            name="circle-plus"
            class="icon-20"
            [ngStyle]="{ color: '#0A7EA4' }"
          ></i-tabler>
        </mat-icon>
        <a
          id="menu-mobile-add-customer"
          (click)="navigateTo('/apps/addCustomer'); filterNavRight.toggle()"
          class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
          ><span class="f-s-15 f-w-500 m-l-8">{{
            'General.AddCustomer' | translate
          }}</span></a
        >
      </div>

      <!-- Treatments -->

      <div class="shortcut-line">
        <mat-icon matSuffix>
          <i-tabler
            name="massage"
            class="icon-20"
            [ngStyle]="{ color: '#0A7EA4' }"
          ></i-tabler>
        </mat-icon>
        <a
          id="menu-mobile-treatment"
          (click)="navigateTo('/apps/treatment'); filterNavRight.toggle()"
          class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
          ><span class="f-s-15 f-w-500 m-l-8">{{
            'General.Treatments' | translate
          }}</span></a
        >
      </div>

      <div class="shortcut-line-sub">
        <mat-icon matSuffix>
          <i-tabler
            name="circle-plus"
            class="icon-20"
            [ngStyle]="{ color: '#0A7EA4' }"
          ></i-tabler>
        </mat-icon>
        <a
          id="menu-mobile-add-treatment"
          (click)="navigateTo('/apps/addTreatment'); filterNavRight.toggle()"
          class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
          ><span class="f-s-15 f-w-500 m-l-8">{{
            'General.AddTreatment' | translate
          }}</span></a
        >
      </div>

      <!-- ToDo -->

      <div class="shortcut-line">
        <mat-icon matSuffix>
          <i-tabler
            name="edit"
            class="icon-20"
            [ngStyle]="{ color: '#0A7EA4' }"
          ></i-tabler>
        </mat-icon>
        <a
          id="menu-mobile-to-do"
          (click)="navigateTo('/apps/taskboard'); filterNavRight.toggle()"
          class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
          ><span class="f-s-15 f-w-500 m-l-8">{{
            'General.ToDo' | translate
          }}</span></a
        >
      </div>

      <!-- Logout -->

      <div class="shortcut-line">
        <mat-icon matSuffix>
          <i-tabler
            name="logout"
            class="icon-20"
            [ngStyle]="{ color: '#0A7EA4' }"
          ></i-tabler>
        </mat-icon>
        <a
          id="menu-mobile-logout"
          [routerLink]="['/apps/logout']"
          (click)="navigateTo('/apps/logout'); filterNavRight.toggle()"
          class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
          ><span class="f-s-15 f-w-500 m-l-8">{{
            'General.Logout' | translate
          }}</span></a
        >
      </div>
    </div>
  </div>
</mat-drawer>
