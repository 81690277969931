import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FeedbackDialog } from 'src/app/models/feedback-dialog.model';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: [
    './feedback-dialog.component.scss',
    '../../../../assets/scss/style.scss'
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule
  ]
})
export class AppFeedbackDialog {
  constructor(
    public dialogRef: MatDialogRef<AppFeedbackDialog>, // Inject MatDialogRef
    private translate: TranslateService,
    private authService: AuthService,
    private settings: CoreService,
    @Inject(MAT_DIALOG_DATA) public data: FeedbackDialog
  ) {}
  options = this.settings.getOptions();

  closeDialog() {
    console.log('AppFeedbackDialog closeDialog()');
    this.dialogRef.close(); // Method to close the dialog
  }

  getBannerClass(): string {
    switch (this.options.activeTheme) {
      case 'blue_theme':
        return 'banner-blue';
      case 'aqua_theme':
        return 'banner-aqua';
      case 'purple_theme':
        return 'banner-purple';
      case 'green_theme':
        return 'banner-green';
      case 'cyan_theme':
        return 'banner-cyan';
      case 'orange_theme':
        return 'banner-orange';
      default:
        return '';
    }
  }
  getButtonClass(): string {
    switch (this.options.activeTheme) {
      case 'blue_theme':
        return 'button-blue';
      case 'aqua_theme':
        return 'button-aqua';
      case 'purple_theme':
        return 'button-purple';
      case 'green_theme':
        return 'button-green';
      case 'cyan_theme':
        return 'button-cyan';
      case 'orange_theme':
        return 'button-orange';
      default:
        return '';
    }
  }
  getIcon(): string {
    switch (this.data.style) {
      case 'info':
        return 'info';
      case 'warning':
        return 'warning';
      case 'error':
        return 'error';
      default:
        return 'info';
    }
  }
}
