import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AdService } from 'src/app/services/ad.service';

declare global {
  interface Window {
    onYouTubeIframeAPIReady: any;
    YT: any;
  }
}

@Component({
  selector: 'app-full-screen-ad',
  templateUrl: './full-screen-ad.component.html',
  styleUrls: ['./full-screen-ad.component.scss']
})
export class FullScreenAdComponent implements OnInit, AfterViewInit {
  @ViewChild('adContainer') adContainer: ElementRef;
  showFullScreenAd$: Observable<boolean>;

  showCloseButton = false;
  player: any;

  videoIds: string[] = [
    '3cCYoV1WqD8',
    '9ZZKiPDGCL4',
    'Rrq9oCRH0oE',
    'TMkMcWC9gs4',
    'OrxN-3TBSM8',
    'Gi9aIuFquUE'
  ];

  constructor(
    private translate: TranslateService,
    private adService: AdService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.cdRef.detectChanges(); // Manually trigger change detection
    this.showFullScreenAd$ = this.adService.fullScreenAd$;
    this.cdRef.detectChanges(); // Manually trigger change detection
    window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady.bind(this);
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.head.appendChild(tag);
  }

  ngAfterViewInit(): void {}

  getRandomVideoId(): string {
    const randomIndex = Math.floor(Math.random() * this.videoIds.length);
    return this.videoIds[randomIndex];
  }

  onYouTubeIframeAPIReady() {
    const randomVideoId = this.getRandomVideoId();
    this.player = new window.YT.Player('player', {
      height: '315',
      width: '560',
      videoId: randomVideoId,
      playerVars: {
        autoplay: 1,
        controls: 0,
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      },
      events: {
        onReady: this.onPlayerReady.bind(this),
        onStateChange: this.onPlayerStateChange.bind(this)
      }
    });
  }

  onPlayerReady(event: any) {
    // console.log('## onPlayerReady', event.data);
    event.target.mute(); // Mute the video to allow autoplay
    event.target.playVideo();
  }

  onPlayerStateChange(event: any) {
    if (event.data === 0) {
      // YT.PlayerState.ENDED
      // console.log('## onPlayerStateChange, We close now!');
      this.adService.hideFullScreenAd();
      this.cdRef.detectChanges(); // Manually trigger change detection
    }
  }
}
