import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Address } from '../models/address.model';
import { CustomersService } from './customers.service';
import { LocalService } from './local.service';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackService } from './feedback.service';
import { FeedbackDialog } from '../models/feedback-dialog.model';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  constructor(
    private http: HttpClient,
    private customersService: CustomersService,
    private localService: LocalService,
    private translate: TranslateService,
    private feedbackService: FeedbackService
  ) {}

  //
  // Fetch an address
  //
  // Fetch an address
  getAddressHttp(): Observable<Address | null> {
    const sanitizedUsername = this.localService.getSanitizedUsername();
    const baseUri = `/addresses/get?id=${sanitizedUsername}`;
    const uri = `${baseUri}&addrId=${this.customersService.getAddressIdBasedOnCustomerId()}`;

    return this.http.get<Address>(uri).pipe(
      map(response => response as Address),
      catchError(error => {
        console.error('Error fetching Address', error);
        const subject = new Subject<void>();

        const feedbackDialog = {
          id: 'E115',
          title: this.translate.instant('Dialog.Error'),
          message: `${this.translate.instant('Dialog.AllAddressesNotFetched')}`,
          style: 'warning'
        } as FeedbackDialog;
        this.feedbackService.setFeedbackStore(feedbackDialog, () => {
          subject.next(); // Signal completion
          subject.complete();
        });

        return subject.pipe(map(() => null as any));
      })
    );
  }
}
