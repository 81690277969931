export const environment = {
  firebase: {
    apiKey: 'AIzaSyAEoEi6ptH_XcSGBVdR3ms4-jwHZlHUcDI',
    authDomain: 'beauty-files.firebaseapp.com',
    projectId: 'beauty-files',
    storageBucket: 'beauty-files.appspot.com',
    messagingSenderId: '983116171460',
    appId: '1:983116171460:web:ef430e46135993f160e191'
  },
  production: true,
  demo: true,
  apiUrl: 'https://demo-api.salon-cale.pl',
  uat: false
};
