import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Visit } from '../models/visits.model';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { UtilData } from '../shared/util/util-data';
import { CustomersService } from './customers.service';
import { FeedbackService } from './feedback.service';
import { ProgressSpinnerService } from './progress-spinner.service';
import { TreatmentsService } from './treatments.service';
import { LocalService } from './local.service';
import { SubscriptionService } from './subscription.service';
// import { VisitsService } from './visits.service';
import { UserSettingsService } from './user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class SmsService {
  constructor(
    private http: HttpClient,
    private treatmentsService: TreatmentsService,
    private customersService: CustomersService,
    // private visitsService: VisitsService,
    private progressSpinnerService: ProgressSpinnerService,
    private feedbackService: FeedbackService,
    private translate: TranslateService,
    private router: Router,
    private utilData: UtilData,
    private localService: LocalService,
    private subscriptionService: SubscriptionService,
    private userSettingsService: UserSettingsService
  ) {}

  //
  // Http create visit
  //

  smsCreateHttp(visit: Visit): void {
    if (!this.localService.getSanitizedUsername()) {
      return;
    }
    const sanitizedUsername = this.localService.getSanitizedUsername();
    this.progressSpinnerService.show();
    const uriSms = `/sms/create?id=${sanitizedUsername}`;

    const user_id = sanitizedUsername;
    const scheduledTime = new Date(visit.notification);

    const visitPhone = this.customersService.getCustomerBasedOnId(
      +visit.customerId
    ).phone;

    const phone = !visitPhone.startsWith('0048')
      ? '0048' + visitPhone
      : visitPhone;

    const e_confirmation =
      this.userSettingsService.getUserSettingsStore().content.eConfirmation;
    const notifyStartText =
      this.userSettingsService.getUserSettingsStore().content
        .notifyStartText === ''
        ? 'Gabinet '
        : this.userSettingsService.getUserSettingsStore().content
            .notifyStartText;
    const msg =
      notifyStartText +
      ` ${this.translate.instant('Settings.NotificationText')}:
      ` +
      this.formatDate(new Date(visit.startDate));

    const data = {
      user_id: user_id,
      phone: phone,
      message: msg,
      scheduledTime: scheduledTime,
      status: false,
      e_confirmation: e_confirmation
    };

    const subscriptions =
      this.subscriptionService.getSubscriptionStore().content;
    const subscription = subscriptions.find(sub => sub.smsCounter > 0);
    if (
      subscription &&
      this.userSettingsService.getUserSettingsStore().content.notification
    ) {
      subscription.smsCounter -= 1; // Decrement the smsCounter
      this.subscriptionService.updateSubscriptionStore({
        content: subscriptions
      });

      this.http.post(uriSms, data).subscribe({
        next: response => {},
        error: error => {},
        complete: () => {
          this.progressSpinnerService.hide();
        }
      });
    }
  }

  decrementFirstSubscriptionSmsCounter(): void {
    const subscriptions =
      this.subscriptionService.getSubscriptionStore().content;
    const subscription = subscriptions.find(sub => sub.smsCounter > 0);
    if (subscription) {
      subscription.smsCounter -= 1; // Decrement the smsCounter
      this.subscriptionService.updateSubscriptionStore({
        content: subscriptions
      }); // Update the store
    } else {
    }
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year}, ${hours}:${minutes}`;
  }
}
