<div class="custom-spinner-container">
  <div class="spinner">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
    <img
      src="../../../../assets/images/logos/salon-care-logo.png"
      alt="Logo"
      class="spinner-logo"
    />
  </div>
</div>
