import { CommonModule, NgIf } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  RouterModule,
  Router,
  NavigationEnd,
  ActivatedRoute,
  Data
} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment.demo';

declare var adsbygoogle: any[];

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterModule, NgIf, TranslateModule],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['../../../../../styles.scss', './breadcrumb.component.scss']
})
export class AppBreadcrumbComponent implements OnInit, AfterViewInit {
  showAds$: Observable<boolean>;
  isDemo = environment.demo;
  pageInfo: Data | any = Object.create(null);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private authService: AuthService
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.titleService.setTitle(event['title']);
        this.pageInfo = event;
      });
  }

  ngOnInit(): void {
    this.showAds$ = this.authService.user$.pipe(
      map((user: firebase.User | null) => {
        if (user) {
          return this.authService.isFreePlan();
        } else {
          return true; // Show ads if not logged in
        }
      })
    );
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   try {
    //     // console.log('Attempting to push adsbygoogle...');
    //   //  (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
    //     // Apply inline styles directly to ensure they take precedence
    //     // const adElements = document.querySelectorAll('.adsbygoogle.ads_resp');
    //     // adElements.forEach(el => {
    //     //   console.log('Setting styles for ad element:', el);
    //     //   (el as HTMLElement).style.width = '100%';
    //     //   (el as HTMLElement).style.maxHeight = '90px';
    //     //   (el as HTMLElement).style.height = '90px';
    //     //   (el as HTMLElement).style.overflow = 'hidden';
    //     // });
    //   } catch (e) {
    //     console.error('ads error:', e);
    //   }
    // }, 900);
  }
}
