import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackDialog } from '../models/feedback-dialog.model';
import { AppFeedbackDialog } from '../pages/apps/feedback-dialog/feedback-dialog.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private readonly _feedbackStore = new Subject<FeedbackDialog | null>();

  constructor(private dialog: MatDialog) {}

  //
  // Setter feedback with optional callback
  //
  setFeedbackStore(
    feedbackDialog: FeedbackDialog,
    afterCloseCallback?: () => void
  ): void {
    this._feedbackStore.next(feedbackDialog);
    this.showDialog(feedbackDialog, afterCloseCallback);
  }

  //
  // Clean feedback message
  //
  cleanFeedbackStore(): void {
    this._feedbackStore.next(null);
  }

  //
  // Exposed feedback message as Observable
  //
  readonly feedbackStore$ = this._feedbackStore.asObservable();

  //
  // Show dialog method with callback
  //
  showDialog(
    feedbackDialog: FeedbackDialog,
    afterCloseCallback?: () => void
  ): void {
    // console.log('FeedbackService msg=', feedbackDialog);
    const dialogRef = this.dialog.open(AppFeedbackDialog, {
      data: feedbackDialog,
      maxWidth: '400px',
      width: '90%',
      panelClass: 'custom-dialog-container',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(() => {
      if (afterCloseCallback) {
        afterCloseCallback(); // Execute the callback function if provided
      }
    });
  }
}
