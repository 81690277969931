<!-- Ads below: -->
<div *ngIf="showAds$ | async">
  <!-- <ins
    class="adsbygoogle ads_resp"
    style="display: block"
    data-ad-client="ca-pub-4868990209731969"
    data-ad-slot="9875374373"
    data-ad-format="horizontal"
    data-full-width-responsive="true"
  ></ins> -->
  <!-- <script>
    (adsbygoogle = window.adsbygoogle || []).push({});
  </script> -->
</div>

<div
  class="bg-light-primary-gradient rounded p-y-30 p-x-24 m-b-20 overflow-hidden"
  *ngIf="  pageInfo?.['title'] != 'Salon-Care'"
>
  <div class="row">
    <div class="col-sm-8">
      <!-- app.component.html -->

      <!-- <div *ngIf="isDemo" class="demo-banner">Demo version, read only mode</div> -->

      <h4 class="page-title m-0 f-s-20 f-w-600 m-b-16">
        {{  'General.' + pageInfo?.['title'] | translate }}
      </h4>
      <div class="d-flex align-items-center overflow-hidden">
        <ng-template
          ngFor
          let-url
          [ngForOf]="pageInfo?.['urls']"
          let-last="last"
        >
          <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
            <a [routerLink]="url.url">{{
              'General.' + url.title | translate
            }}</a>
          </li>
          <li class="breadcrumb-item active" *ngIf="last">
            {{ 'General.' + url.title | translate }}
          </li>
        </ng-template>
      </div>
    </div>
    <div class="col-sm-4 text-right position-relative">
      <div class="breadcrumb-icon"></div>
    </div>
  </div>
</div>
