import { Component } from '@angular/core';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  standalone: false
  // imports: [MatProgressSpinnerModule, MatDialogModule, CustomSpinnerComponent]
})
export class AppProgressSpinner {}
