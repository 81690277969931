import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings, defaults } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  get notify(): Observable<Record<string, any>> {
    return this.notify$.asObservable();
  }
  private notify$ = new BehaviorSubject<Record<string, any>>({});
  getOptions() {
    return this.options;
  }
  setOptions(options: AppSettings) {
    this.options = Object.assign(defaults, options);
    this.notify$.next(this.options);
  }
  private options = defaults;
  getLanguage() {
    return this.options.language;
  }
  setLanguage(lang: string) {
    this.options.language = lang;
    this.notify$.next({ lang });
  }
}
