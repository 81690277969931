import { NavItem } from './vertical/sidebar/nav-item/nav-item';

const shortCutItems = [
  'Home',
  'Calendar',
  'Visits',
  'Customers',
  'Treatments',
  'Notes',
  'ToDo'
];

export const navItems: NavItem[] = [
  {
    navCap: 'HOME'
  },
  {
    displayName: 'Home',
    iconName: 'home',
    route: ''
  },
  {
    displayName: 'Calendar',
    iconName: 'calendar-event',
    route: 'apps/calendar'
  },
  {
    displayName: 'Visits',
    iconName: 'list',
    route: 'apps/visit'
  },
  {
    displayName: 'Customers',
    iconName: 'man',
    route: 'apps/customer'
  },
  {
    displayName: 'Treatments',
    iconName: 'massage',
    route: 'apps/treatment'
  },
  {
    displayName: 'ToDo',
    iconName: 'edit',
    route: 'apps/taskboard'
  },
  {
    displayName: 'Statistics',
    iconName: 'chart-dots',
    route: 'apps/statistics'
  },
  {
    navCap: 'PAYMENT'
  },
  {
    displayName: 'Pricing',
    iconName: 'currency-dollar',
    route: 'apps/pricing'
  },
  {
    navCap: 'INFO'
  },
  {
    displayName: 'About',
    iconName: 'help',
    route: 'apps/about'
  },
  {
    displayName: 'FAQ',
    iconName: 'checklist',
    route: 'apps/faq'
  },
  {
    displayName: 'Terms and conditions',
    iconName: 'report',
    route: 'apps/terms-and-conditions'
  },
  {
    displayName: 'Contact',
    iconName: 'mail',
    route: 'apps/contact'
  },
  {
    displayName: 'MySettings',
    iconName: 'settings',
    route: 'apps/user-setting'
  },
  {
    navCap: 'AUTH'
  },
  {
    displayName: 'Login',
    iconName: 'login',
    route: 'apps/login'
  },
  {
    displayName: 'Register',
    iconName: 'user-plus',
    route: 'apps/register'
  },
  {
    displayName: 'Logout',
    iconName: 'logout',
    route: 'apps/logout'
  }
];
// TODO: End development mode
export const shortCutNavItems: NavItem[] = navItems.filter(navItem => {
  return shortCutItems.some(shortNavItem => {
    return shortNavItem === navItem.displayName;
  });
});

export const restCutNavItems: NavItem[] = navItems.filter(navItem =>
  shortCutNavItems.every(shortItem => shortItem !== navItem.displayName)
);
