import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { CoreService } from 'src/app/services/core.service';
import { AppSettings } from 'src/app/app.config';
import { filter, map } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { navItems } from './menu-data';
import { NavService } from '../../services/nav.service';
import { AppNavItemComponent } from './vertical/sidebar/nav-item/nav-item.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TablerIconsModule } from 'angular-tabler-icons';
import { HeaderComponent } from './vertical/header/header.component';
// import {AppHorizontalHeaderComponent} from './horizontal/header/header.component';
// import {AppHorizontalSidebarComponent} from './horizontal/sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './shared/customizer/customizer.component';
import { TranslateModule } from '@ngx-translate/core';
import { LocalService } from 'src/app/services/local.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { UserSettingsService } from 'src/app/services/user-settings.service';

const MOBILE_VIEW = 'screen and (max-width: 768px)';
const TABLET_VIEW = 'screen and (min-width: 769px) and (max-width: 1024px)';
const MONITOR_VIEW = 'screen and (min-width: 1024px)';

@Component({
  selector: 'app-full',
  standalone: true,
  imports: [
    RouterModule,
    AppNavItemComponent,
    MaterialModule,
    CommonModule,
    NgScrollbarModule,
    TablerIconsModule,
    HeaderComponent,
    // AppHorizontalHeaderComponent,
    // AppHorizontalSidebarComponent,
    AppBreadcrumbComponent,
    CustomizerComponent,
    TranslateModule
  ],
  templateUrl: './full.component.html',
  styleUrls: ['../../../styles.scss', './full.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FullComponent implements OnInit {
  name$: Observable<string>;
  navItems = navItems;
  repeatArray: number[] = Array(10).fill(0);

  @ViewChild('leftsidenav')
  public sidenav: MatSidenav;
  @ViewChild('content', { static: true }) content!: MatSidenavContent;
  //get options from service
  options = this.settings.getOptions();
  private layoutChangesSubscription = Subscription.EMPTY;
  private isMobileScreen = false;
  private isContentWidthFixed = true;
  private isCollapsedWidthFixed = false;
  private htmlElement!: HTMLHtmlElement;

  get isOver(): boolean {
    return this.isMobileScreen;
  }
  @ViewChild('leftsidenav') sidenavRef!: ElementRef;
  public isSidenavExpanded = false;
  checkWidth(nr: number) {
    this.isSidenavExpanded = !this.isSidenavExpanded;
    this.cd.detectChanges();
  }
  navigate() {
    this.router.navigate(['/dashboards/dashboard1']);
  }

  navigateTo(path: string): void {
    this.router.navigate([path]);
  }

  constructor(
    private settings: CoreService,
    private mediaMatcher: MediaMatcher,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private navService: NavService,
    private localService: LocalService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    public userSettingsService: UserSettingsService
  ) {
    this.htmlElement = document.querySelector('html')!;
    this.layoutChangesSubscription = this.breakpointObserver
      .observe([MOBILE_VIEW, TABLET_VIEW, MONITOR_VIEW])
      .subscribe(state => {
        this.options.sidenavOpened = true;
        this.isMobileScreen = state.breakpoints[MOBILE_VIEW];
        if (this.options.sidenavCollapsed == false) {
          this.options.sidenavCollapsed = state.breakpoints[TABLET_VIEW];
        }
        this.isContentWidthFixed = state.breakpoints[MONITOR_VIEW];
      });

    // Initialize project theme with options
    this.receiveOptions(this.options);

    // This is for scroll to top
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(e => {
        this.content.scrollTo({ top: 0 });
      });
  }
  user$ = this.authService.user$;
  isDemo = environment.demo;

  ngOnInit(): void {
    const authentication$ = this.authService.appUserStore$.pipe(
      map(ev => {
        return ev.content;
      })
    );

    this.name$ = this.user$.pipe(map(ev => ev?.displayName ?? 'Not logged in'));
  }
  ngOnDestroy() {
    this.layoutChangesSubscription.unsubscribe();
  }

  toggleCollapsed() {
    this.isContentWidthFixed = false;
    this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
    this.resetCollapsedState();
  }

  resetCollapsedState(timer = 400) {
    setTimeout(() => this.settings.setOptions(this.options), timer);
  }

  onSidenavClosedStart() {
    this.isContentWidthFixed = false;
  }

  onSidenavOpenedChange(isOpened: boolean) {
    this.isCollapsedWidthFixed = !this.isOver;
    this.options.sidenavOpened = isOpened;
    this.settings.setOptions(this.options);
  }

  receiveOptions(options: AppSettings): void {
    this.options = options;
    this.toggleDarkTheme(options);
  }

  toggleDarkTheme(options: AppSettings) {
    if (options.theme === 'dark') {
      this.htmlElement.classList.add('dark-theme');
      this.htmlElement.classList.remove('light-theme');
    } else {
      this.htmlElement.classList.remove('dark-theme');
      this.htmlElement.classList.add('light-theme');
    }
  }
  getName() {
    return this.localService.getData('given_name') ?? '';
  }

  logout() {
    this.authService.logout();
  }
  login() {
    this.authService.login();
  }
}
