import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  map,
  of,
  tap
} from 'rxjs';
import { Visit, VisitStore, VisitTable } from '../models/visits.model';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import { addHours } from 'date-fns';
import { CustomerStore } from '../models/customer.model';
import { FeedbackDialog } from '../models/feedback-dialog.model';
import { Treatment, TreatmentStore } from '../models/treatment.model';
import { UtilData } from '../shared/util/util-data';
import { CustomersService } from './customers.service';
import { FeedbackService } from './feedback.service';
import { ProgressSpinnerService } from './progress-spinner.service';
import { TreatmentsService } from './treatments.service';
import { LocalService } from './local.service';
import { SmsService } from './sms.service';
import { environment } from 'src/environments/environment';

const colors: any = {
  red: {
    primary: '#fa896b',
    secondary: '#fdede8'
  },
  blue: {
    primary: '#5d87ff',
    secondary: '#ecf2ff'
  },
  yellow: {
    primary: '#ffae1f',
    secondary: '#fef5e5'
  },
  green: {
    primary: '#d3f0de',
    secondary: '#d3f0de'
  }
};

@Injectable({
  providedIn: 'root'
})
export class VisitsService {
  constructor(
    private http: HttpClient,
    private treatmentsService: TreatmentsService,
    private customersService: CustomersService,
    private progressSpinnerService: ProgressSpinnerService,
    private feedbackService: FeedbackService,
    private translate: TranslateService,
    private router: Router,
    private utilData: UtilData,
    private localService: LocalService,
    private smsService: SmsService
  ) {}
  actions: CalendarEventAction[] = [];

  //
  // Store clicked hourSegment on Calendar
  //

  private selectedDateSubject = new BehaviorSubject<Date | null>(null);
  public selectedDate$ = this.selectedDateSubject.asObservable();

  public setSelectedDate(date: Date): void {
    this.selectedDateSubject.next(date);
  }

  //
  // Store of visit id
  //
  private _visitIdStore = new BehaviorSubject<number>(0);
  public readonly visitId$ = this._visitIdStore.asObservable(); // it is read only

  setVisitIdStore(id: number): void {
    this._visitIdStore.next(id);
  }

  //
  // Clean visit Id Store
  //
  cleanVisitIdStore(): void {
    this._visitIdStore.next(0);
  }

  //
  // Expose Visit's Id as UNObservable, as number
  //
  getVisitIdStore(): number {
    return this._visitIdStore.getValue();
  }

  //
  // Store of visit collection
  //
  private readonly _visitStore = new BehaviorSubject<VisitStore>({
    content: []
  } as VisitStore);

  //
  // Setter visit collection
  //
  private _setVisitStore(visitStore: VisitStore): void {
    this._visitStore.next(visitStore);
  }

  //
  // Exposed all visit collection as Observable
  //
  readonly visitStore$ = this._visitStore.asObservable();

  //
  // Exposed all visit collection as UNObservable
  //
  getVisitStore(): VisitStore {
    return this._visitStore.getValue();
  }

  //
  // Safe update of visit collection
  //
  updateVisitStore(visitStore: VisitStore): void {
    this._setVisitStore({
      ...this.getVisitStore(),
      ...visitStore
    });
  }

  //
  // update of visit collection with the response priority over VisitStore content
  //
  updateAndCompleteVisitStore(newVisits: Visit[]): void {
    const existingVisits = this.getVisitStore().content;
    const updatedVisitsMap = new Map<number, Visit>();

    // First, add or update visits from the newVisits to the map
    newVisits.forEach(visit => updatedVisitsMap.set(visit.id, visit));

    existingVisits.forEach(visit => {
      if (!updatedVisitsMap.has(visit.id)) {
        updatedVisitsMap.set(visit.id, visit);
      }
    });

    // Convert the map values back to an array for the visit store
    const mergedVisits = Array.from(updatedVisitsMap.values());

    this._setVisitStore({ content: mergedVisits });
  }

  //
  // Clean project's visit
  //
  cleanVisitStore(): void {
    this._setVisitStore({
      content: []
    } as VisitStore);
  }

  getVisit$(id: number): Observable<Visit> {
    return this.visitStore$.pipe(
      map(visitStore => visitStore.content),
      map(visits => visits.filter(visit => visit.id === id)[0])
    );
  }

  getVisitBasedOnVisitId(): Visit {
    return (
      this.getVisitStore().content.filter(
        visit => visit.id === this.getVisitIdStore()
      )[0] ?? null
    );
  }

  //
  // Visit Duration in minutes
  //
  visitDuration(startDateString: string, endDateString: string): number {
    const millisecondsPerMinute = 60000;
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const durationInMilliseconds = endDate.getTime() - startDate.getTime();
    const durationInMinutes = durationInMilliseconds / millisecondsPerMinute;
    return Math.abs(durationInMinutes);
  }

  //
  // Get Treatments by Id array
  //

  getTreatmentsbyIds(ids: number[]): Treatment[] {
    return this.treatmentsService
      .getTreatmentStore()
      .content.filter(treatment => ids.includes(treatment.id));
  }

  //
  // Get Visit by Id
  //

  getVisitById(visitId: number): Visit {
    return this.getVisitStore().content.filter(
      visit => visit.id === visitId
    )[0];
  }

  //
  // Get Treatments for a visit
  //

  getTreatmentsForVisit(visitId: number): Treatment[] {
    // Find the visit by ID
    const visit = this.getVisitById(visitId);
    if (!visit) {
      return [];
    }

    const treatments = this.treatmentsService
      .getTreatmentStore()
      .content.filter((treatment: Treatment) =>
        visit.treatmentsIds.includes(treatment.id)
      );

    return treatments;
  }

  //
  // Update time of event in VisitStore
  //
  updateVisitStoreTime(event: CalendarEvent, newStart: any, newEnd: any): void {
    const events = this.getVisitStore().content.map(storeEvent => {
      if (storeEvent.id === event.meta) {
        return {
          ...storeEvent,
          startDate: newStart.toString(),
          endDate: newEnd.toString()
        };
      }
      return storeEvent;
    });
    this.updateVisitStore({ content: events });
  }

  calendarEvents(): CalendarEvent[] {
    // Fetch all necessary data upfront to minimize lookups
    const customers = this.customersService.getCustomerStore().content;
    const treatments = this.treatmentsService.getTreatmentStore().content;
    const visits = this.getVisitStore().content;

    // Map each visit to a CalendarEvent
    const calendarEvents = visits.map(visit => {
      // Find the customer for the current visit
      const customer = customers.find(c => c.id === visit.customerId);

      // Map each treatmentId in the visit to its corresponding treatment name
      const treatmentNames = visit.treatmentsIds
        .map(id => {
          const treatment = treatments.find(t => t.id === id);
          return treatment ? treatment.name : '';
        })
        .filter(name => name); // Remove any undefined entries

      // Construct the title using customer name and joined treatment names
      const title = `${
        customer
          ? customer.name + ' ' + (customer.surname || '')
          : 'Unknown Customer'
      }<br>${treatmentNames.join(', ')}`;

      // Find color objects
      const primaryColorObj = this.utilData.EVENT_COLORS.find(
        color => color.id === visit.config.color.primary
      );
      const secondaryColorObj = this.utilData.EVENT_COLORS.find(
        color => color.id === visit.config.color.secondary
      );

      // Construct the CalendarEvent object
      return {
        id: visit.id,
        start: new Date(visit.startDate),
        end: new Date(visit.endDate),
        title: title,
        color: {
          primary: primaryColorObj ? primaryColorObj.value : '#FFFFFF', // Fallback color
          secondary: secondaryColorObj ? secondaryColorObj.value : '#FFFFFF'
        }
      };
    });

    return calendarEvents;
  }

  getCalendarEventsFromStore(): CalendarEvent[] {
    const customers = this.customersService.getCustomerStore().content;
    const treatments = this.treatmentsService.getTreatmentStore().content;
    const visits = this.getVisitStore().content;

    let calendarEventArray = [] as CalendarEvent[];

    this.getVisitStore().content.forEach(visit => {
      const treatmentIdArray = visit.treatmentsIds; //.split(',');
      let treatmentNameArray: string[] = [];
      treatmentIdArray.forEach(treatmentId => {
        const treatmentFound = this.treatmentsService
          .getTreatmentStore()
          .content.filter(treatment => treatment.id === treatmentId)[0];

        if (treatmentFound) {
          treatmentNameArray.push(treatmentFound.name);
        }
      });
      const treatmentsArr = this.treatmentsService
        .getTreatmentStore()
        .content.filter(treatment => treatment.id);

      const primaryColorObj = this.utilData.EVENT_COLORS.find(
        color => color.id === visit.config.color.primary
      );
      const secondaryColorObj = this.utilData.EVENT_COLORS.find(
        color => color.id === visit.config.color.secondary
      );
      const customer = customers.find(c => c.id === visit.customerId);

      const treatmentNames = visit.treatmentsIds
        .map(id => {
          const treatment = treatments.find(t => t.id === id);
          return treatment ? treatment.name : '';
        })
        .filter(name => name); // Remove any undefined entries

      // Construct the title using customer name and joined treatment names
      const title = `${
        customer
          ? customer.name + ' ' + (customer.surname || '')
          : 'Unknown Customer'
      }<br>${treatmentNames.join(', ')}`;

      const calendarEvent = {
        id: visit.id,
        start: addHours(new Date(visit.startDate), 0),
        end: addHours(new Date(visit.endDate), 0),

        title: ' ' + ': ' + title, //treatmentNameArray.toString(),
        color: {
          primary: primaryColorObj ? primaryColorObj.value : '#FFFFFF', // Fallback color
          secondary: secondaryColorObj ? secondaryColorObj.value : '#FFFFFF'
        },
        // config: visit.config,
        actions: this.actions,
        meta: visit.id
      } as unknown as CalendarEvent;

      calendarEventArray.push(calendarEvent);
    });

    return calendarEventArray as CalendarEvent[];
  }

  //
  // Http request for visits
  //

  getAllVisitsHttp(afterDate?: Date, beforeDate?: Date): Observable<void> {
    const sanitizedUsername = this.localService.getSanitizedUsername();

    const now = new Date();

    // For AdSense purpose
    const startDate = afterDate
      ? afterDate
      : new Date(now.getFullYear(), now.getMonth() - 2, now.getDate());

    const endDate = beforeDate
      ? beforeDate
      : new Date(now.getFullYear(), now.getMonth() + 3, now.getDate());

    const payload = {
      afterDate: startDate.toISOString().split('T')[0], // "YYYY-MM-DD" format
      beforeDate: endDate.toISOString().split('T')[0] // "YYYY-MM-DD" format
    };

    this.cleanVisitStore();
    const uri = `/visits/filter-time?id=${sanitizedUsername}`;

    return this.http.post<Visit[]>(uri, payload).pipe(
      tap(response => {
        const allVisits = response as Visit[];
        this.updateAndCompleteVisitStore(allVisits);
        this.getCalendarEventsFromStore();
      }),
      map(() => void 0),
      catchError(error => {
        console.error('Error fetching visits', error);
        return of(void 0);
      }),
      tap(() => {})
    );
  }

  //
  // Http delete visit
  //

  deleteVisitHttp(visitId: number): void {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return;
    }
    if (!this.localService.getSanitizedUsername()) {
      return;
    }
    this.progressSpinnerService.show();

    const sanitizedUsername = this.localService.getSanitizedUsername();

    const baseUri = `/visits/delete?id=${sanitizedUsername}`;
    const options = {
      body: { id: visitId }
    };

    this.http
      .delete(`${baseUri}`, options)
      .pipe()
      .subscribe({
        next: response => {
          const updatedVisits = this.getVisitStore().content.filter(
            visit => visit.id !== visitId
          );
          const deletedVisit = this.getVisitStore().content.filter(
            visit => visit.id == visitId
          )[0];
          const customer = this.customersService.getCustomerBasedOnId(
            deletedVisit.customerId
          );
          const feedbackDialog = {
            title: this.translate.instant('Dialog.Info'),
            message: `${this.translate.instant('Dialog.VisitDeleted')}: <br>
            Visit : ${customer.name} ${customer.surname} <br>`,

            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
          this.updateVisitStore({ content: updatedVisits });

          //TODO: Perform next action: remove its Visits etc
          //TODO: return message for dialog
        },
        error: error => {
          const feedbackDialog = {
            id: 'E206',
            title: this.translate.instant('Dialog.Error'),
            message: `${this.translate.instant('Dialog.VisitNotDeleted')}`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
        },
        complete: () => {
          this.progressSpinnerService.hide();
        }
      });
  }

  visitTableStore$(): Observable<VisitTable[]> {
    const customerStore$ = this.customersService
      .customerStore$ as Observable<CustomerStore>;
    const treatmentStore$ = this.treatmentsService
      .treatmentStore$ as Observable<TreatmentStore>;
    const visitStore$ = this.visitStore$ as Observable<VisitStore>;

    return combineLatest([customerStore$, visitStore$, treatmentStore$]).pipe(
      map(([customerStore, visitStore, treatmentStore]) => {
        return visitStore.content.map(visit => {
          const customer = customerStore.content.find(
            c => c.id === visit.customerId
          );
          const customerName = customer
            ? `${customer.name}${
                customer.surname ? ' ' + customer.surname : ''
              }`
            : 'Unknown Customer';

          const treatments = visit.treatmentsIds
            .map(treatmentId =>
              treatmentStore.content.find(
                treatment => treatment.id === treatmentId
              )
            )
            .filter(treatment => treatment !== undefined) as Treatment[]; // Filter out any undefined results from find

          return {
            id: visit.id,
            startDate: visit.startDate,
            endDate: visit.endDate,
            customer: customerName, // Concatenated name and surname
            treatments: treatments,
            notification: visit.notification,
            totalCost: visit.totalCost,
            paid: visit.paid,
            discount: visit.discount,
            note: visit.note,
            config: visit.config
          } as VisitTable;
        });
      })
    );
  }

  getVisitTable(): VisitTable[] {
    const customerStore = this.customersService.getCustomerStore();
    const visitStore = this.getVisitStore();
    const treatmentStore = this.treatmentsService.getTreatmentStore();

    // Filter out visits that don't have a corresponding customer
    const validVisits = visitStore.content.filter(visit =>
      customerStore.content.some(c => c.id === visit.customerId)
    );

    return validVisits.map(visit => {
      // Find the customer; since we already filtered visits without a valid customer, this find operation is expected to succeed.
      const customer = customerStore.content.find(
        c => c.id === visit.customerId
      )!;
      const customerName = `${customer.name}${
        customer.surname ? ' ' + customer.surname : ''
      }`;

      // Map treatmentIds to treatments, filtering out any that are not found
      const treatments = visit.treatmentsIds.reduce((acc, treatmentId) => {
        const treatment = treatmentStore.content.find(
          t => t.id === treatmentId
        );
        if (treatment) {
          acc.push(treatment);
        }
        return acc;
      }, [] as Treatment[]);

      return {
        id: visit.id,
        startDate: visit.startDate,
        endDate: visit.endDate,
        customer: customerName, // Concatenate name and surname
        treatments: treatments,
        notification: visit.notification,
        totalCost: visit.totalCost,
        paid: visit.paid,
        discount: visit.discount,
        note: visit.note,
        config: visit.config
      };
    });
  }
  // PUT Modify

  //
  // Http update visit
  //

  updateVisitHttp(visitUpdate: Visit): void {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return;
    }
    if (!this.localService.getSanitizedUsername()) {
      return;
    }
    this.progressSpinnerService.show();

    const sanitizedUsername = this.localService.getSanitizedUsername();

    const uriVisit = `/visits/update?id=${sanitizedUsername}`;

    type ResponseNewVisitType = {
      visit?: Visit;
      message: string;
    };

    this.http.put<ResponseNewVisitType>(uriVisit, visitUpdate).subscribe({
      next: response => {
        const message = response.message;
        const allVisits = this.getVisitStore().content as Visit[];

        const updatedVisits = allVisits.map((visit: Visit) =>
          visit.id === visitUpdate.id
            ? {
                ...visit,
                startDate: visitUpdate.startDate,
                endDate: visitUpdate.endDate,
                customerId: visitUpdate.customerId,
                treatmentsIds: visitUpdate.treatmentsIds,
                notification: visitUpdate.notification,
                totalCost: visitUpdate.totalCost,
                paid: visitUpdate.paid,
                discount: visitUpdate.discount,
                note: visitUpdate.note,
                config: visitUpdate.config
              }
            : visit
        );
        this.updateVisitStore({ content: updatedVisits });
        const customer = this.customersService.getCustomerBasedOnId(
          visitUpdate.customerId
        );

        const feedbackDialog = {
          title: this.translate.instant('Dialog.Info'),
          message: `${this.translate.instant('Dialog.VisitUpdated')}: <br>
            ${customer.name} ${customer.surname} <br>`,
          style: 'warning'
        } as FeedbackDialog;
        this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
      },
      error: error => {
        const customer = this.customersService.getCustomerBasedOnId(
          visitUpdate.customerId
        );

        const feedbackDialog = {
          id: 'E102',
          title: this.translate.instant('Dialog.Error'),
          message: `${this.translate.instant('Dialog.VisitError')}: <br>
          ${customer.name} ${customer.surname} <br>`,
          style: 'warning'
        } as FeedbackDialog;
        this.feedbackService.setFeedbackStore(feedbackDialog, () => {});

        const currentPath = this.router.url;
        if (
          currentPath === '/apps/addVisit' ||
          currentPath === '/apps/editVisit'
        ) {
          this.router.navigate(['/apps/visit']);
        } else {
          this.router.navigate(['/apps/calendar']);
        }
      },
      complete: () => {
        this.progressSpinnerService.hide();

        const currentPath = this.router.url;
        if (
          currentPath === '/apps/addVisit' ||
          currentPath === '/apps/editVisit'
        ) {
          this.router.navigate(['/apps/visit']);
        } else {
          this.router.navigate(['/apps/calendar']);
        }
      }
    });
  }

  //
  // Http create visit
  //

  createVisitHttp(visit: Visit): void {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return;
    }
    if (!this.localService.getSanitizedUsername()) {
      return;
    }
    const sanitizedUsername = this.localService.getSanitizedUsername();
    this.progressSpinnerService.show();

    const uriVisit = `/visits/create?id=${sanitizedUsername}`;

    type ResponseNewVisitType = {
      visit?: Visit;
      message: string;
    };
    this.http.post<ResponseNewVisitType>(uriVisit, visit).subscribe({
      next: response => {
        this.smsService.smsCreateHttp(visit);

        const customer = this.customersService.getCustomerBasedOnId(
          visit.customerId
        );

        const message = response.message;
        const createdVisit = response.visit as Visit;

        const allVisits = this.getVisitStore().content as Visit[];
        allVisits.push(createdVisit);
        this.updateVisitStore({ content: allVisits });
        const feedbackDialog = {
          title: this.translate.instant('Dialog.Info'),
          message: `${this.translate.instant('Dialog.VisitCreated')}: <br>
            <b>${this.translate.instant('Dialog.Customer')}: </b>
              ${customer.name} ${customer.surname}<br>
           <b> Date: </b> ${visit.startDate} <br>`,
          style: 'warning'
        } as FeedbackDialog;
        this.feedbackService.setFeedbackStore(feedbackDialog, () => {
          this.router.navigate(['/dashboards/dashboard1']);
        });
      },
      error: error => {
        const customer = this.customersService.getCustomerBasedOnId(
          visit.customerId
        );

        const feedbackDialog = {
          id: 'E200',
          title: this.translate.instant('Dialog.Error'),
          message: `${this.translate.instant('Dialog.VisitNotCreated')}: <br>
          <b>${this.translate.instant('Dialog.Customer')}: </b>
          ${customer.name} ${customer.surname}<br>
          <b> Date: </b> ${visit.startDate} <br>`,
          style: 'warning'
        } as FeedbackDialog;
        this.feedbackService.setFeedbackStore(feedbackDialog, () => {
          this.router.navigate(['/dashboards/dashboard1']);
        });
      },
      complete: () => {
        this.progressSpinnerService.hide();

        const currentPath = this.router.url;
        if (
          currentPath === '/apps/addVisit' ||
          currentPath === '/apps/editVisit'
        ) {
          this.router.navigate(['/apps/visit']);
        } else {
          this.router.navigate(['/apps/calendar']);
        }
      }
    });
  }

  updateVisitDates(
    visitId: number,
    newStart: Date,
    newEnd: Date
  ): Visit | null {
    const visitIndex = this.getVisitStore().content.findIndex(
      visit => visit.id === visitId
    );

    if (visitIndex !== -1) {
      const updatedVisit = {
        ...this.getVisitStore().content[visitIndex],
        startDate: newStart.toISOString(),
        endDate: newEnd.toISOString()
      };
      return updatedVisit;
    } else {
      // Visit not found
      return null;
    }
  }

  findOldestVisitDate(): Date | null {
    const visitStore = this.getVisitStore().content;
    if (visitStore.length === 0) {
      return null; // Return null if there are no visits
    }

    let oldestDate = new Date(visitStore[0].startDate); // Initialize with the first visit's date
    for (const visit of visitStore) {
      const visitDate = new Date(visit.startDate);
      if (visitDate < oldestDate) {
        oldestDate = visitDate;
      }
    }
    return oldestDate;
  }
  findLatestVisitStartDate(): Date | null {
    const visitStore = this.getVisitStore().content;

    if (visitStore.length === 0) {
      return null; // or throw an error if no visits exist
    }

    const latestVisit = visitStore.reduce((latest, visit) => {
      const latestStartDate = new Date(latest.startDate);
      const currentStartDate = new Date(visit.startDate);
      return currentStartDate > latestStartDate ? visit : latest;
    });

    return new Date(latestVisit.startDate);
  }
}
