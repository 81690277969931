import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppProgressSpinner } from '../pages/apps/progress-spinner/progress-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class ProgressSpinnerService {
  private spinnerDialogRef: MatDialogRef<AppProgressSpinner> | undefined;

  constructor(private dialog: MatDialog) {}

  show() {
    if (!this.spinnerDialogRef) {
      this.spinnerDialogRef = this.dialog.open(AppProgressSpinner, {
        disableClose: true,
        hasBackdrop: true,
        backdropClass: 'spinner-backdrop',
        panelClass: 'transparent-dialog'
      });
    }
  }

  hide() {
    if (this.spinnerDialogRef) {
      this.spinnerDialogRef.close();
      this.spinnerDialogRef = undefined;
    }
  }
}
