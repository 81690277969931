import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboards/dashboard1',
        pathMatch: 'full'
      },
      {
        path: 'dashboards',
        loadChildren: () =>
          import('./pages/dashboards/dashboards.module').then(
            m => m.DashboardsModule
          )
      },

      {
        path: 'charts',
        loadChildren: () =>
          import('./pages/charts/charts.module').then(m => m.ChartsModule)
      },
      {
        path: 'apps',
        loadChildren: () =>
          import('./pages/apps/apps.module').then(m => m.AppsModule)
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: []
  },
  {
    path: '**',
    redirectTo: 'dashboards/dashboard1'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
